const cueSet2 = [
  {
    duration: 0.16666649999999983,
    durationTicks: 3840,
    midi: 45,
    name: "A2",
    ticks: 245760,
    time: 10.666656,
    velocity: 0.007874015748031496,
  },
  {
    duration: 0.16666649999999983,
    durationTicks: 3840,
    midi: 45,
    name: "A2",
    ticks: 249600,
    time: 10.8333225,
    velocity: 0.023622047244094488,
  },
  {
    duration: 0.16666649999999983,
    durationTicks: 3840,
    midi: 60,
    name: "C4",
    ticks: 257280,
    time: 11.1666555,
    velocity: 0.5039370078740157,
  },
  {
    duration: 0.33333300000000143,
    durationTicks: 7680,
    midi: 64,
    name: "E4",
    ticks: 261120,
    time: 11.333321999999999,
    velocity: 0.4881889763779528,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 60,
    name: "C4",
    ticks: 268800,
    time: 11.666655,
    velocity: 0.4566929133858268,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 52,
    name: "E3",
    ticks: 276480,
    time: 11.999988,
    velocity: 0.5118110236220472,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 48,
    name: "C3",
    ticks: 284160,
    time: 12.333321,
    velocity: 0.5275590551181102,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 45,
    name: "A2",
    ticks: 291840,
    time: 12.666654,
    velocity: 0.5275590551181102,
  },
  {
    duration: 0.16666649999999983,
    durationTicks: 3840,
    midi: 43,
    name: "G2",
    ticks: 307200,
    time: 13.33332,
    velocity: 0.007874015748031496,
  },
  {
    duration: 0.16666649999999983,
    durationTicks: 3840,
    midi: 43,
    name: "G2",
    ticks: 311040,
    time: 13.4999865,
    velocity: 0.007874015748031496,
  },
  {
    duration: 0.16666649999999983,
    durationTicks: 3840,
    midi: 59,
    name: "B3",
    ticks: 318720,
    time: 13.8333195,
    velocity: 0.47244094488188976,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 62,
    name: "D4",
    ticks: 322560,
    time: 13.999986,
    velocity: 0.4566929133858268,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 59,
    name: "B3",
    ticks: 330240,
    time: 14.333319,
    velocity: 0.4881889763779528,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 50,
    name: "D3",
    ticks: 337920,
    time: 14.666652,
    velocity: 0.5669291338582677,
  },
  {
    duration: 0.33333300000000143,
    durationTicks: 7680,
    midi: 47,
    name: "B2",
    ticks: 345600,
    time: 14.999984999999999,
    velocity: 0.5905511811023622,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 43,
    name: "G2",
    ticks: 353280,
    time: 15.333318,
    velocity: 0.5905511811023622,
  },
  {
    duration: 0.16666649999999983,
    durationTicks: 3840,
    midi: 41,
    name: "F2",
    ticks: 364800,
    time: 15.8333175,
    velocity: 0.023622047244094488,
  },
  {
    duration: 0.16666649999999983,
    durationTicks: 3840,
    midi: 41,
    name: "F2",
    ticks: 368640,
    time: 15.999984,
    velocity: 0.023622047244094488,
  },
  {
    duration: 0.1666665000000016,
    durationTicks: 3840,
    midi: 41,
    name: "F2",
    ticks: 372480,
    time: 16.1666505,
    velocity: 0.015748031496062992,
  },
  {
    duration: 0.1666665000000016,
    durationTicks: 3840,
    midi: 57,
    name: "A3",
    ticks: 380160,
    time: 16.4999835,
    velocity: 0.47244094488188976,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 60,
    name: "C4",
    ticks: 384000,
    time: 16.66665,
    velocity: 0.47244094488188976,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 57,
    name: "A3",
    ticks: 391680,
    time: 16.999983,
    velocity: 0.4566929133858268,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 48,
    name: "C3",
    ticks: 399360,
    time: 17.333316,
    velocity: 0.5905511811023622,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 45,
    name: "A2",
    ticks: 407040,
    time: 17.666649,
    velocity: 0.6062992125984252,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 41,
    name: "F2",
    ticks: 414720,
    time: 17.999982,
    velocity: 0.6062992125984252,
  },
  {
    duration: 0.1666665000000016,
    durationTicks: 3840,
    midi: 40,
    name: "E2",
    ticks: 422400,
    time: 18.333315,
    velocity: 0.07874015748031496,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 40,
    name: "E2",
    ticks: 426240,
    time: 18.4999815,
    velocity: 0.007874015748031496,
  },
  {
    duration: 0.1666665000000016,
    durationTicks: 3840,
    midi: 40,
    name: "E2",
    ticks: 430080,
    time: 18.666648,
    velocity: 0.007874015748031496,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 40,
    name: "E2",
    ticks: 433920,
    time: 18.8333145,
    velocity: 0.007874015748031496,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 57,
    name: "A3",
    ticks: 441600,
    time: 19.1666475,
    velocity: 0.44881889763779526,
  },
  {
    duration: 0.3333330000000032,
    durationTicks: 7680,
    midi: 59,
    name: "B3",
    ticks: 445440,
    time: 19.333313999999998,
    velocity: 0.47244094488188976,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 55,
    name: "G3",
    ticks: 453120,
    time: 19.666647,
    velocity: 0.4881889763779528,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 47,
    name: "B2",
    ticks: 460800,
    time: 19.99998,
    velocity: 0.6220472440944882,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 43,
    name: "G2",
    ticks: 468480,
    time: 20.333313,
    velocity: 0.6220472440944882,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 40,
    name: "E2",
    ticks: 476160,
    time: 20.666646,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.1666665000000016,
    durationTicks: 3840,
    midi: 45,
    name: "A2",
    ticks: 491520,
    time: 21.333312,
    velocity: 0.007874015748031496,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 45,
    name: "A2",
    ticks: 495360,
    time: 21.4999785,
    velocity: 0.023622047244094488,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 60,
    name: "C4",
    ticks: 503040,
    time: 21.8333115,
    velocity: 0.5039370078740157,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 64,
    name: "E4",
    ticks: 506880,
    time: 21.999978,
    velocity: 0.4881889763779528,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 60,
    name: "C4",
    ticks: 514560,
    time: 22.333311,
    velocity: 0.4566929133858268,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 52,
    name: "E3",
    ticks: 522240,
    time: 22.666643999999998,
    velocity: 0.5118110236220472,
  },
  {
    duration: 0.3333330000000032,
    durationTicks: 7680,
    midi: 48,
    name: "C3",
    ticks: 529920,
    time: 22.999976999999998,
    velocity: 0.5275590551181102,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 45,
    name: "A2",
    ticks: 537600,
    time: 23.33331,
    velocity: 0.5275590551181102,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 43,
    name: "G2",
    ticks: 552960,
    time: 23.999976,
    velocity: 0.007874015748031496,
  },
  {
    duration: 0.1666665000000016,
    durationTicks: 3840,
    midi: 43,
    name: "G2",
    ticks: 556800,
    time: 24.1666425,
    velocity: 0.007874015748031496,
  },
  {
    duration: 0.1666665000000016,
    durationTicks: 3840,
    midi: 59,
    name: "B3",
    ticks: 564480,
    time: 24.499975499999998,
    velocity: 0.47244094488188976,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 62,
    name: "D4",
    ticks: 568320,
    time: 24.666642,
    velocity: 0.4566929133858268,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 59,
    name: "B3",
    ticks: 576000,
    time: 24.999975,
    velocity: 0.4881889763779528,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 50,
    name: "D3",
    ticks: 583680,
    time: 25.333308,
    velocity: 0.5669291338582677,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 47,
    name: "B2",
    ticks: 591360,
    time: 25.666641,
    velocity: 0.5905511811023622,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 43,
    name: "G2",
    ticks: 599040,
    time: 25.999973999999998,
    velocity: 0.5905511811023622,
  },
  {
    duration: 0.1666665000000016,
    durationTicks: 3840,
    midi: 41,
    name: "F2",
    ticks: 610560,
    time: 26.4999735,
    velocity: 0.023622047244094488,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 41,
    name: "F2",
    ticks: 614400,
    time: 26.66664,
    velocity: 0.023622047244094488,
  },
  {
    duration: 0.1666665000000016,
    durationTicks: 3840,
    midi: 41,
    name: "F2",
    ticks: 618240,
    time: 26.8333065,
    velocity: 0.015748031496062992,
  },
  {
    duration: 0.1666665000000016,
    durationTicks: 3840,
    midi: 57,
    name: "A3",
    ticks: 625920,
    time: 27.1666395,
    velocity: 0.47244094488188976,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 60,
    name: "C4",
    ticks: 629760,
    time: 27.333306,
    velocity: 0.47244094488188976,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 57,
    name: "A3",
    ticks: 637440,
    time: 27.666639,
    velocity: 0.4566929133858268,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 48,
    name: "C3",
    ticks: 645120,
    time: 27.999972,
    velocity: 0.5905511811023622,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 45,
    name: "A2",
    ticks: 652800,
    time: 28.333305,
    velocity: 0.6062992125984252,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 41,
    name: "F2",
    ticks: 660480,
    time: 28.666638,
    velocity: 0.6062992125984252,
  },
  {
    duration: 0.1666665000000016,
    durationTicks: 3840,
    midi: 40,
    name: "E2",
    ticks: 668160,
    time: 28.999971,
    velocity: 0.07874015748031496,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 40,
    name: "E2",
    ticks: 672000,
    time: 29.1666375,
    velocity: 0.007874015748031496,
  },
  {
    duration: 0.1666665000000016,
    durationTicks: 3840,
    midi: 40,
    name: "E2",
    ticks: 675840,
    time: 29.333304,
    velocity: 0.007874015748031496,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 40,
    name: "E2",
    ticks: 679680,
    time: 29.4999705,
    velocity: 0.007874015748031496,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 57,
    name: "A3",
    ticks: 687360,
    time: 29.8333035,
    velocity: 0.44881889763779526,
  },
  {
    duration: 0.3333330000000032,
    durationTicks: 7680,
    midi: 59,
    name: "B3",
    ticks: 691200,
    time: 29.999969999999998,
    velocity: 0.47244094488188976,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 55,
    name: "G3",
    ticks: 698880,
    time: 30.333303,
    velocity: 0.4881889763779528,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 47,
    name: "B2",
    ticks: 706560,
    time: 30.666636,
    velocity: 0.6220472440944882,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 43,
    name: "G2",
    ticks: 714240,
    time: 30.999969,
    velocity: 0.6220472440944882,
  },
  {
    duration: 0.33333299999999966,
    durationTicks: 7680,
    midi: 40,
    name: "E2",
    ticks: 721920,
    time: 31.333302,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.1666665000000016,
    durationTicks: 3840,
    midi: 45,
    name: "A2",
    ticks: 737280,
    time: 31.999968,
    velocity: 0.007874015748031496,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 45,
    name: "A2",
    ticks: 741120,
    time: 32.1666345,
    velocity: 0.023622047244094488,
  },
  {
    duration: 0.16666650000000516,
    durationTicks: 3840,
    midi: 60,
    name: "C4",
    ticks: 748800,
    time: 32.4999675,
    velocity: 0.5039370078740157,
  },
  {
    duration: 0.3333329999999961,
    durationTicks: 7680,
    midi: 64,
    name: "E4",
    ticks: 752640,
    time: 32.666634,
    velocity: 0.4881889763779528,
  },
  {
    duration: 0.3333330000000032,
    durationTicks: 7680,
    midi: 60,
    name: "C4",
    ticks: 760320,
    time: 32.999967,
    velocity: 0.4566929133858268,
  },
  {
    duration: 0.3333329999999961,
    durationTicks: 7680,
    midi: 52,
    name: "E3",
    ticks: 768000,
    time: 33.3333,
    velocity: 0.5118110236220472,
  },
  {
    duration: 0.3333330000000032,
    durationTicks: 7680,
    midi: 48,
    name: "C3",
    ticks: 775680,
    time: 33.666633,
    velocity: 0.5275590551181102,
  },
  {
    duration: 0.3333329999999961,
    durationTicks: 7680,
    midi: 45,
    name: "A2",
    ticks: 783360,
    time: 33.999966,
    velocity: 0.5275590551181102,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 43,
    name: "G2",
    ticks: 798720,
    time: 34.666632,
    velocity: 0.007874015748031496,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 43,
    name: "G2",
    ticks: 802560,
    time: 34.8332985,
    velocity: 0.007874015748031496,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 59,
    name: "B3",
    ticks: 810240,
    time: 35.1666315,
    velocity: 0.47244094488188976,
  },
  {
    duration: 0.3333330000000032,
    durationTicks: 7680,
    midi: 62,
    name: "D4",
    ticks: 814080,
    time: 35.333298,
    velocity: 0.4566929133858268,
  },
  {
    duration: 0.3333329999999961,
    durationTicks: 7680,
    midi: 59,
    name: "B3",
    ticks: 821760,
    time: 35.666631,
    velocity: 0.4881889763779528,
  },
  {
    duration: 0.3333330000000032,
    durationTicks: 7680,
    midi: 50,
    name: "D3",
    ticks: 829440,
    time: 35.999964,
    velocity: 0.5669291338582677,
  },
  {
    duration: 0.3333329999999961,
    durationTicks: 7680,
    midi: 47,
    name: "B2",
    ticks: 837120,
    time: 36.333297,
    velocity: 0.5905511811023622,
  },
  {
    duration: 0.3333330000000032,
    durationTicks: 7680,
    midi: 43,
    name: "G2",
    ticks: 844800,
    time: 36.66663,
    velocity: 0.5905511811023622,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 41,
    name: "F2",
    ticks: 856320,
    time: 37.1666295,
    velocity: 0.023622047244094488,
  },
  {
    duration: 0.16666650000000516,
    durationTicks: 3840,
    midi: 41,
    name: "F2",
    ticks: 860160,
    time: 37.333296,
    velocity: 0.023622047244094488,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 41,
    name: "F2",
    ticks: 864000,
    time: 37.4999625,
    velocity: 0.015748031496062992,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 57,
    name: "A3",
    ticks: 871680,
    time: 37.8332955,
    velocity: 0.47244094488188976,
  },
  {
    duration: 0.3333330000000032,
    durationTicks: 7680,
    midi: 60,
    name: "C4",
    ticks: 875520,
    time: 37.999962,
    velocity: 0.47244094488188976,
  },
  {
    duration: 0.3333329999999961,
    durationTicks: 7680,
    midi: 57,
    name: "A3",
    ticks: 883200,
    time: 38.333295,
    velocity: 0.4566929133858268,
  },
  {
    duration: 0.3333330000000032,
    durationTicks: 7680,
    midi: 48,
    name: "C3",
    ticks: 890880,
    time: 38.666627999999996,
    velocity: 0.5905511811023622,
  },
  {
    duration: 0.3333330000000032,
    durationTicks: 7680,
    midi: 45,
    name: "A2",
    ticks: 898560,
    time: 38.999961,
    velocity: 0.6062992125984252,
  },
  {
    duration: 0.3333329999999961,
    durationTicks: 7680,
    midi: 41,
    name: "F2",
    ticks: 906240,
    time: 39.333294,
    velocity: 0.6062992125984252,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 40,
    name: "E2",
    ticks: 913920,
    time: 39.666627,
    velocity: 0.07874015748031496,
  },
  {
    duration: 0.16666650000000516,
    durationTicks: 3840,
    midi: 40,
    name: "E2",
    ticks: 917760,
    time: 39.833293499999996,
    velocity: 0.007874015748031496,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 40,
    name: "E2",
    ticks: 921600,
    time: 39.99996,
    velocity: 0.007874015748031496,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 40,
    name: "E2",
    ticks: 925440,
    time: 40.1666265,
    velocity: 0.007874015748031496,
  },
  {
    duration: 0.16666650000000516,
    durationTicks: 3840,
    midi: 57,
    name: "A3",
    ticks: 933120,
    time: 40.499959499999996,
    velocity: 0.44881889763779526,
  },
  {
    duration: 0.3333329999999961,
    durationTicks: 7680,
    midi: 59,
    name: "B3",
    ticks: 936960,
    time: 40.666626,
    velocity: 0.47244094488188976,
  },
  {
    duration: 0.3333330000000032,
    durationTicks: 7680,
    midi: 55,
    name: "G3",
    ticks: 944640,
    time: 40.999959,
    velocity: 0.4881889763779528,
  },
  {
    duration: 0.3333329999999961,
    durationTicks: 7680,
    midi: 47,
    name: "B2",
    ticks: 952320,
    time: 41.333292,
    velocity: 0.6220472440944882,
  },
  {
    duration: 0.3333330000000032,
    durationTicks: 7680,
    midi: 43,
    name: "G2",
    ticks: 960000,
    time: 41.666624999999996,
    velocity: 0.6220472440944882,
  },
  {
    duration: 0.3333329999999961,
    durationTicks: 7680,
    midi: 40,
    name: "E2",
    ticks: 967680,
    time: 41.999958,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 45,
    name: "A2",
    ticks: 983040,
    time: 42.666624,
    velocity: 0.007874015748031496,
  },
  {
    duration: 0.16666650000000516,
    durationTicks: 3840,
    midi: 45,
    name: "A2",
    ticks: 986880,
    time: 42.8332905,
    velocity: 0.023622047244094488,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 60,
    name: "C4",
    ticks: 994560,
    time: 43.1666235,
    velocity: 0.5039370078740157,
  },
  {
    duration: 0.3333330000000032,
    durationTicks: 7680,
    midi: 64,
    name: "E4",
    ticks: 998400,
    time: 43.33329,
    velocity: 0.4881889763779528,
  },
  {
    duration: 0.3333329999999961,
    durationTicks: 7680,
    midi: 60,
    name: "C4",
    ticks: 1006080,
    time: 43.666623,
    velocity: 0.4566929133858268,
  },
  {
    duration: 0.3333330000000032,
    durationTicks: 7680,
    midi: 52,
    name: "E3",
    ticks: 1013760,
    time: 43.999956,
    velocity: 0.5118110236220472,
  },
  {
    duration: 0.3333329999999961,
    durationTicks: 7680,
    midi: 48,
    name: "C3",
    ticks: 1021440,
    time: 44.333289,
    velocity: 0.5275590551181102,
  },
  {
    duration: 0.3333330000000032,
    durationTicks: 7680,
    midi: 45,
    name: "A2",
    ticks: 1029120,
    time: 44.666622,
    velocity: 0.5275590551181102,
  },
  {
    duration: 0.16666650000000516,
    durationTicks: 3840,
    midi: 43,
    name: "G2",
    ticks: 1044480,
    time: 45.333287999999996,
    velocity: 0.007874015748031496,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 43,
    name: "G2",
    ticks: 1048320,
    time: 45.4999545,
    velocity: 0.007874015748031496,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 59,
    name: "B3",
    ticks: 1056000,
    time: 45.8332875,
    velocity: 0.47244094488188976,
  },
  {
    duration: 0.3333330000000032,
    durationTicks: 7680,
    midi: 62,
    name: "D4",
    ticks: 1059840,
    time: 45.999953999999995,
    velocity: 0.4566929133858268,
  },
  {
    duration: 0.3333330000000032,
    durationTicks: 7680,
    midi: 59,
    name: "B3",
    ticks: 1067520,
    time: 46.333287,
    velocity: 0.4881889763779528,
  },
  {
    duration: 0.3333329999999961,
    durationTicks: 7680,
    midi: 50,
    name: "D3",
    ticks: 1075200,
    time: 46.66662,
    velocity: 0.5669291338582677,
  },
  {
    duration: 0.3333330000000032,
    durationTicks: 7680,
    midi: 47,
    name: "B2",
    ticks: 1082880,
    time: 46.999953,
    velocity: 0.5905511811023622,
  },
  {
    duration: 0.3333329999999961,
    durationTicks: 7680,
    midi: 43,
    name: "G2",
    ticks: 1090560,
    time: 47.333286,
    velocity: 0.5905511811023622,
  },
  {
    duration: 0.16666650000000516,
    durationTicks: 3840,
    midi: 41,
    name: "F2",
    ticks: 1102080,
    time: 47.833285499999995,
    velocity: 0.023622047244094488,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 41,
    name: "F2",
    ticks: 1105920,
    time: 47.999952,
    velocity: 0.023622047244094488,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 41,
    name: "F2",
    ticks: 1109760,
    time: 48.1666185,
    velocity: 0.015748031496062992,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 57,
    name: "A3",
    ticks: 1117440,
    time: 48.4999515,
    velocity: 0.47244094488188976,
  },
  {
    duration: 0.3333329999999961,
    durationTicks: 7680,
    midi: 60,
    name: "C4",
    ticks: 1121280,
    time: 48.666618,
    velocity: 0.47244094488188976,
  },
  {
    duration: 0.3333330000000032,
    durationTicks: 7680,
    midi: 57,
    name: "A3",
    ticks: 1128960,
    time: 48.999950999999996,
    velocity: 0.4566929133858268,
  },
  {
    duration: 0.3333329999999961,
    durationTicks: 7680,
    midi: 48,
    name: "C3",
    ticks: 1136640,
    time: 49.333284,
    velocity: 0.5905511811023622,
  },
  {
    duration: 0.3333330000000032,
    durationTicks: 7680,
    midi: 45,
    name: "A2",
    ticks: 1144320,
    time: 49.666616999999995,
    velocity: 0.6062992125984252,
  },
  {
    duration: 0.3333330000000032,
    durationTicks: 7680,
    midi: 41,
    name: "F2",
    ticks: 1152000,
    time: 49.99995,
    velocity: 0.6062992125984252,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 40,
    name: "E2",
    ticks: 1159680,
    time: 50.333283,
    velocity: 0.07874015748031496,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 40,
    name: "E2",
    ticks: 1163520,
    time: 50.4999495,
    velocity: 0.007874015748031496,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 40,
    name: "E2",
    ticks: 1167360,
    time: 50.666616,
    velocity: 0.007874015748031496,
  },
  {
    duration: 0.16666650000000516,
    durationTicks: 3840,
    midi: 40,
    name: "E2",
    ticks: 1171200,
    time: 50.833282499999996,
    velocity: 0.007874015748031496,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 57,
    name: "A3",
    ticks: 1178880,
    time: 51.1666155,
    velocity: 0.44881889763779526,
  },
  {
    duration: 0.3333330000000032,
    durationTicks: 7680,
    midi: 59,
    name: "B3",
    ticks: 1182720,
    time: 51.333282,
    velocity: 0.47244094488188976,
  },
  {
    duration: 0.3333329999999961,
    durationTicks: 7680,
    midi: 55,
    name: "G3",
    ticks: 1190400,
    time: 51.666615,
    velocity: 0.4881889763779528,
  },
  {
    duration: 0.3333330000000032,
    durationTicks: 7680,
    midi: 47,
    name: "B2",
    ticks: 1198080,
    time: 51.999947999999996,
    velocity: 0.6220472440944882,
  },
  {
    duration: 0.3333329999999961,
    durationTicks: 7680,
    midi: 43,
    name: "G2",
    ticks: 1205760,
    time: 52.333281,
    velocity: 0.6220472440944882,
  },
  {
    duration: 0.3333330000000032,
    durationTicks: 7680,
    midi: 40,
    name: "E2",
    ticks: 1213440,
    time: 52.666613999999996,
    velocity: 0.6299212598425197,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 45,
    name: "A2",
    ticks: 1228800,
    time: 53.33328,
    velocity: 0.007874015748031496,
  },
  {
    duration: 0.16666649999999805,
    durationTicks: 3840,
    midi: 45,
    name: "A2",
    ticks: 1232640,
    time: 53.4999465,
    velocity: 0.023622047244094488,
  },
  {
    duration: 4.8333285,
    durationTicks: 111360,
    midi: 60,
    name: "C4",
    ticks: 1240320,
    time: 53.833279499999996,
    velocity: 0.5039370078740157,
  },
];

export default cueSet2;
